import React, {FC, useEffect, useState} from 'react';
import DocumentGeneration from "../../../../components/DocumentGeneration/DocumentGeneration";
import {useQuery} from "@tanstack/react-query";
import {requests} from "../../../../services/http-common";
import { getYupDynamicScheme } from "../../../../components/DocumentGeneration/helpers/yupValidationHelpers";

interface TPrivateFile83bProps {
    companyId: number;
    templateCode: string;
    paymentEnabled: boolean;
    templateSpouseCode?: string;
    email?: string;
    fullName?: string;
}

const PrivateFile83b:FC<TPrivateFile83bProps> = ({
 companyId,
 templateCode,
 templateSpouseCode,
 email = '',
 fullName = '',
 paymentEnabled,
}) => {
    const [templateId, setTemplateId] = useState('');
    const [isSupposeExists, setSuppose] = useState<boolean>(false);
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const fileId = urlParams.get('fileId') || '';
    const file83bUpdate = Boolean(fileId);
    const templateIdNotSet = templateId === '';
    const editPrefix = file83bUpdate ? `/edit` : '';
    const signAfterEmbeddedPaymentOrderNumber = urlParams.get('rdn');

    const { data: preFilledFormValues, isLoading: preFilledFormValuesLoading } = useQuery({
        queryKey: ['pre-filled-values', companyId],
        queryFn: async () => {
            return requests.get(
                `/api/companies/${companyId}/file83b/${fileId}`,
            );
        },
        gcTime: 0,
        enabled: file83bUpdate && !signAfterEmbeddedPaymentOrderNumber,
    });

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: ['required-fields', isSupposeExists, companyId],
        queryFn: async () => {
            return requests.get(
                `/api/companies/${companyId}/file83b/getPlaceholdersByTemplateCodeDb/${templateId}${editPrefix}`,
            );
        },
        gcTime: 0,
        enabled: !templateIdNotSet && !signAfterEmbeddedPaymentOrderNumber,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: ['doc-as-html', isSupposeExists, companyId],
        queryFn: async () => {
            return requests.get(`/api/companies/${companyId}/file83b/exportDocAsHtml/${templateId}`);
        },
        gcTime: 0,
        enabled: !templateIdNotSet && !signAfterEmbeddedPaymentOrderNumber,
    });

    const setSupposeHandler = (isExist: boolean) => {
        const newTemplateId = isExist ? templateSpouseCode : templateCode;
        setSuppose(isExist);
        setTemplateId(newTemplateId as string);
    }
    useEffect(
        () => {
            if (preFilledFormValues?.templateId && templateIdNotSet) {
                setTemplateId(preFilledFormValues?.templateId);
            }
        }, [preFilledFormValues?.templateId]
    );
    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs) : undefined;
    return (
        <div>
            <DocumentGeneration
                companyId={companyId}
                email={email}
                fullName={fullName}
                isSupposeExists={isSupposeExists}
                setSuppose={setSupposeHandler}
                template={templateId}
                data={fieldsConfiguration}
                requiredFieldsLoading={requiredFieldsLoading}
                docAsHtml={docAsHtml}
                docAsHtmlLoading={docAsHtmlLoading}
                preFilledFormValues={preFilledFormValues}
                file83bUpdate={file83bUpdate}
                preFilledFormValuesLoading={preFilledFormValuesLoading}
                fileId={fileId}
                showSelectTemplate={Boolean(templateSpouseCode)}
                signAfterEmbeddedPaymentOrderNumber={signAfterEmbeddedPaymentOrderNumber}
                paymentEnabled={paymentEnabled}
                formSchema={formSchema}
            />
        </div>
    )
}

export default PrivateFile83b;
