import React, { FC } from 'react';
import * as Yup from "yup";
import { Password } from 'primereact/password';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import styles from "../authentication.module.scss";
import FieldWrapper from "../../../uiComponents/FieldWrapper/FieldWrapper";
import {InputText} from "primereact/inputtext";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {NavLink} from "react-router-dom";

export type TFieldName = 'name' | 'email' | 'password' | 'confirmPassword';
export type TFieldConfig = {
    fieldName: TFieldName;
    label: string;
    placeholder: string;
    type: 'text' | 'password';
};

export type TSignUpValues = Record<TFieldName, string>;

const fieldsConfig: TFieldConfig[] = [
    {
        fieldName: 'name',
        label: 'Name',
        placeholder: '',
        type: 'text',
    },
    {
        fieldName: 'email',
        label: 'Email',
        placeholder: 'example@gmail.com',
        type: 'text',
    },
    {
        fieldName: 'password',
        label: 'Password',
        placeholder: '',
        type: 'password',
    },
    {
        fieldName: 'confirmPassword',
        label: 'Confirm password',
        placeholder: '',
        type: 'password',
    }
];

interface TSignUpFormProps {
    onSubmit: (values: TSignUpValues) => void;
    isLoading: boolean;
    invitedUserEmail: string;
    invitedUserName: string;
    clearFormError: () => void;
    formError?: string;
}

const SignUpForm: FC<TSignUpFormProps> = ({
onSubmit,
isLoading,
invitedUserEmail,
invitedUserName,
formError,
clearFormError,
}) => {
    const formSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Confirm password is required'),
    });
    const initialFormValues = {
        name: invitedUserName,
        email: invitedUserEmail,
        password: '',
        confirmPassword: '',
    };
    const form = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: initialFormValues,
    });
    const { control, handleSubmit, watch } = form;
    const formErrors = form.formState.errors;
    const currentValues = watch();
    const { password, confirmPassword } = currentValues;
    const onChangeAnyField = () => {
        if (formError) {
            clearFormError();
        }
    }
    return (
        <form className={styles.authForm} onSubmit={handleSubmit(onSubmit)}>
            <p className={styles.headTxt}>Create your Account</p>
            {/*<p className={styles.infoTxt}>Lorem ipsum dolor sit amet consectetur. Turpis augue.</p>*/}
            <div className={styles.fields}>
                {fieldsConfig.map((fieldConfig: TFieldConfig) => {
                    const {fieldName, placeholder, label, type} = fieldConfig;
                    const fieldError = formErrors[fieldName]?.message;
                    const isEmailField = fieldName === 'email';
                    if (type === 'password') {
                        return (
                            <div className={styles.fieldItem} key={fieldName}>
                                <Controller
                                    name={fieldName as TFieldName}
                                    control={control}
                                    render={({field}) => {
                                        return (
                                            <FieldWrapper
                                                className={styles.fieldWide}
                                                errorMessage={fieldError}
                                                label={label}
                                                required
                                            >
                                                <Password
                                                    className={styles.fieldWide}
                                                    value={field.value as string}
                                                    name={fieldName}
                                                    placeholder={placeholder}
                                                    onChange={(e) => {
                                                        onChangeAnyField();
                                                        field.onChange(e.target.value);
                                                    }}
                                                    autoComplete="new-password"
                                                    feedback={false}
                                                    toggleMask
                                                />
                                            </FieldWrapper>
                                        );
                                    }}
                                />
                            </div>
                        );
                    }
                    return (
                        <div className={styles.fieldItem} key={fieldName}>
                            <Controller
                                name={fieldName as TFieldName}
                                control={control}
                                render={({field}) => {
                                    return (
                                        <FieldWrapper
                                            className={styles.fieldWide}
                                            errorMessage={fieldError}
                                            label={label}
                                            required
                                        >
                                            <InputText
                                                className={styles.fieldWide}
                                                value={field.value as string}
                                                type='text'
                                                name={fieldName}
                                                placeholder={placeholder}
                                                onChange={(e) => {
                                                    onChangeAnyField();
                                                    field.onChange(e.target.value);
                                                }}
                                                {...(isEmailField ? { autoComplete: 'new-password' } : {})}
                                            />
                                        </FieldWrapper>
                                    );
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            {
                formError && (
                    <div className={styles.errorRow}>{formError}</div>
                )
            }
            <ButtonPrimary
                label="Sign Up"
                className={styles.actionBtn}
                type="submit"
                disabled={password !== confirmPassword || isLoading || Boolean(formError)}
            />
            <div className={styles.haveAccountRow}>
                <p className={styles.label}>Have an Account ?</p>
                <NavLink to="/" className={styles.link}>
                    Sign in
                </NavLink>
            </div>
        </form>
    )
}

export default SignUpForm;
