import React, { FC } from "react";

import { useQuery } from "@tanstack/react-query";
import { requests } from "../../services/http-common";
import Layout from "../Layout/Layout";
import LayoutPlaceholder from "../LayoutPlaceholder/LayoutPlaceholder";

interface TAppProps {
  userLogOut: () => void;
  logOutLoading: boolean;
}

export const App: FC<TAppProps> = ({ userLogOut, logOutLoading }) => {
  const paramsUrl = window.location.search;
  const params = new URLSearchParams(paramsUrl);
  const companyId = params.get('companyId');

  const { data: userPerCompanyConfig } = useQuery({
    queryKey: ["general-info"],
    queryFn: async () => {
      return requests.get(`/api/users/info`);
    },
    gcTime: 0,
    refetchInterval: 120000,
    refetchOnWindowFocus: true,
  });

  const companies = userPerCompanyConfig?.companies || [];
  const email = userPerCompanyConfig?.email;
  const userId = userPerCompanyConfig?.id;
  const userName = userPerCompanyConfig?.name;

  if (!userPerCompanyConfig) {
    return (
      <LayoutPlaceholder />
    );
  }
  if (companies.length === 0) {
      return (
          <div>
              No company created
          </div>
      )
  }
  const selectedCompanyId = companyId || sessionStorage.getItem('selectedCompanyId');
  const savedCompanyId =  selectedCompanyId ? Number(selectedCompanyId) : '';
  const defaultCompany = (selectedCompanyId && companies.find((company: any) => company.companyId === savedCompanyId)) || companies[0];
  return (
    <Layout
        defaultCompany={defaultCompany}
        companies={companies}
        email={email}
        userLogOut={userLogOut}
        userName={userName}
        userId={userId}
        logOutLoading={logOutLoading}
    />
  );
};
