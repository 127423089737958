import { classNames } from 'primereact/utils';
import React, { FC, useState, useRef } from 'react';
import { If } from '../If/If';

import styles from './sidebar.module.scss';
import {TUserCompanyConfigItem} from "../../@types/global";
import useOutsideClick from "../../hooks/useOutsideClick";
import {useNavigate} from "react-router-dom";
import oaiLogo from '../../assets/icons/companyLogos/openai.svg';
import wstLogo from '../../assets/icons/companyLogos/westaway.png';
import vglLogo from '../../assets/icons/companyLogos/virgil.png';

const companyLogos: Record<string, string> = {
  OAI: oaiLogo,
  WST: wstLogo,
  VGL: vglLogo,
};

interface IUserSectionProps {
  isOpened: boolean;
  roleLabel: string;
  userName: string;
  companies: TUserCompanyConfigItem[];
  selectedCompanyId: number;
  updateCompanyInfo: React.Dispatch<React.SetStateAction<TUserCompanyConfigItem>>;
  companyCode: string;
}

const UserSection: FC<IUserSectionProps> = ({
  isOpened,
  roleLabel = '',
  userName = '',
  companies,
  selectedCompanyId,
  updateCompanyInfo,
  companyCode,
}) => {
  const [toggled, toggle] = useState<boolean>(false);
  const targetRef = useRef(null);
  const firstLetter = userName[0];
  const navigate = useNavigate();
  const hasLogo = Object.prototype.hasOwnProperty.call(companyLogos, companyCode);
  const handleOutsideClick = () => {
    toggle(false);
  };
  useOutsideClick(targetRef, handleOutsideClick);
  const onSwitchCompany = (companyConfig: TUserCompanyConfigItem) => {
    sessionStorage.setItem('selectedCompanyId', `${companyConfig?.companyId}`);
    updateCompanyInfo(companyConfig);
    toggle(false);
    navigate('/');
  }
  return (
    <div className={classNames(styles.userSection, {
      [styles.isClosed]: !isOpened
    })} ref={targetRef}>
      <div className={styles.userInfo} onClick={() => toggle(true)}>
        {
          hasLogo ? (
              <div className={styles.userCustomLogoHolder}>
                <img
                    src={companyLogos[companyCode]}
                    width="24px"
                    alt=""
                />
              </div>
          ) : (
              <div className={styles.userLogoHolder}>{firstLetter}</div>
          )
        }
        <If condition={isOpened}>
          <>
          <div
              className={classNames(
                styles.nameHolder,
                styles.delayedSmoothAppear,
              )}
            >
              <p className={styles.nameTxt}>{userName}</p>
              <p className={styles.positionTxt}>{roleLabel}</p>
            </div>
            <div className={styles.arrowGroup}>
              <i className="bi bi-caret-up-fill"></i>
              <i className="bi bi-caret-down-fill"></i>
            </div>
          </>
        </If>
      </div>
      {
          isOpened && toggled && (
              <div className={styles.companiesDropdown}>
                <div className={styles.listWrapper}>
                  {
                    companies.map((companyConfig: TUserCompanyConfigItem, index) => {
                      const {companyId, companyName} = companyConfig;
                      return (
                          <div onClick={() => onSwitchCompany(companyConfig)} key={index} className={classNames(styles.listItem, {
                            [styles.selected]: companyId === selectedCompanyId,
                          })}>
                            {companyName}
                          </div>
                      )
                    })
                  }
                </div>
              </div>
          )
      }
    </div>
  );
};

export default UserSection;
