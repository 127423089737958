import React, {useState, FC, useRef} from 'react';
import logo from '../../assets/images/83b-logo-corpora.png';
import styles from './authentication.module.scss';
import axios from "axios";
import {setAuthorizationHeader} from "../../services/http-common";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import SignInForm, {TSignInValues} from "./components/SignInForm";
import ConfirmSignUpForm, {TConfirmValues} from "./components/ConfirmSignUpForm";
import {Nullable} from "primereact/ts-helpers";
import {useNavigate} from "react-router-dom";
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {classNames} from "primereact/utils";

const baseUrl = import.meta.env.VITE_CORPORA_SERVICE_URL;

interface TSignIn {
    userLogIn: () => void;
    isQuickUse?: boolean;
}

const SingIn: FC<TSignIn> = ({ userLogIn, isQuickUse }) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [anotherAccountAuthIssue, setAuthIssue] = useState<boolean>(false);
    const savedUserNameRef = useRef<string>('');
    const savedValues = useRef<Nullable<TSignInValues>>(null);
    const userInfo = sessionStorage.getItem('userInfo');
    const userInfoObj = userInfo ? JSON.parse(userInfo) : {};
    const { show } = useToast();
    const navigate = useNavigate();
    const [formError, setFormErrorValue] = useState<string>('');

    const clearFormError = () => {
        setFormErrorValue('');
    }

    const onSubmit = (values: TSignInValues) => {
        if (localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) {
            setAuthIssue(true);
            return;
        }
        savedValues.current = values;
        savedUserNameRef.current = values.email;
        axios.post(`${baseUrl}api/auth/sign-in`, values)
            .then((response) => {
                const data = response?.data as { token: string; refreshToken: string };
                const { token, refreshToken } = data;
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                setAuthorizationHeader(token);
                userLogIn();
                setLoading(false);
                savedValues.current = null;
                if (userInfo && userInfoObj?.fileId && userInfoObj?.companyId) {
                    sessionStorage.setItem('selectedCompanyId', `${userInfoObj?.companyId}`);
                    navigate(`/filing/file-83b?fileId=${userInfoObj?.fileId}`);
                }
                if (isQuickUse) {
                    setTimeout(() => {
                        navigate('/filing');
                    }, 0);
                }
            })
            .catch((error) => {
                if (error?.response?.status === 434) {
                    setUsername(savedUserNameRef.current);
                } else {
                    const errorData = {
                        error: error,
                        show: show,
                        cb: (errorMessage?: string) => {
                            setFormErrorValue(errorMessage as string);
                            setLoading(false);
                        },
                        hideToastMessage: true,
                    };
                    extractErrorMessage(errorData);
                }
            });
    }

    const onSubmitConfirmSignUpForm = (values: TConfirmValues) => {
        const params = {
            ...values,
            username,
        };
        axios.post(`${baseUrl}api/auth/confirm/email`, params)
            .then(() => {
                setUsername('');
                setLoading(false);
                savedUserNameRef.current = '';
            })
            .catch((error) => {
                const errorData = {
                    error: error,
                    show: show,
                    cb: () => {
                        setLoading(false);
                    }
                };
                extractErrorMessage(errorData);
            });
    }

    const resendCode = () => {
        axios.post(`${baseUrl}api/auth/confirm/resend`, { username })
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                const errorData = {
                    error: error,
                    show: show,
                    cb: () => {
                        setLoading(false);
                    }
                };
                extractErrorMessage(errorData);
            });
    }

    const handleReload = () => {
        window.location.reload();
    };

    if (anotherAccountAuthIssue) {
        return (
            <div className={styles.authentication}>
                <div className={styles.authIssueHolder}>
                    <i className={classNames('bi bi-wrench', styles.wrenchIcon)}></i>
                    <p className={styles.warn1}>Something Went Wrong</p>
                    <p className={styles.warn2}>Please try closing and re-opening your browser window.</p>
                    <ButtonPrimary
                        label="Reload Page"
                        className={styles.reloadBtn}
                        onClick={handleReload}
                    />
                </div>
            </div>
        )
    }

    if (isQuickUse) {
        return (
            <div className={styles.quickUseWrapper}>
                <div className={styles.holder}>
                    {
                        Boolean(username) ? (
                            <ConfirmSignUpForm
                                onSubmit={onSubmitConfirmSignUpForm}
                                isLoading={isLoading}
                                resendCode={resendCode}
                            />
                        ) : (
                            <SignInForm
                                isLoading={isLoading}
                                onSubmit={onSubmit}
                                email={userInfoObj?.email}
                                formError={formError}
                                clearFormError={clearFormError}
                            />
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={styles.authentication}>
            <div className={styles.holder}>
                <div className={styles.logoRow}>
                    <img src={logo} alt="" />
                </div>
                {
                    Boolean(username) ? (
                        <ConfirmSignUpForm
                            onSubmit={onSubmitConfirmSignUpForm}
                            isLoading={isLoading}
                            resendCode={resendCode}
                        />
                    ) : (
                        <SignInForm
                            isLoading={isLoading}
                            onSubmit={onSubmit}
                            email={userInfoObj?.email}
                            formError={formError}
                            clearFormError={clearFormError}
                        />
                    )
                }
                <p className={styles.terms}>
                    © 2024 Corpora, Inc.
                </p>
            </div>
        </div>
    )
}

export default SingIn;
