import React, { FC } from 'react';
import usedIcon from '../../assets/icons/used-sub.svg';
import availableIcon from '../../assets/icons/available-sub.svg';

import styles from './subscriptions.module.scss';

interface TSubscriptionsProps {
    file83bLimit: number;
    remainingCount: number;
}

const Subscriptions: FC<TSubscriptionsProps> = ({ file83bLimit, remainingCount }) => {
  return (
    <div className={styles.subscriptions}>
        <div className={styles.grid}>
            <div className={styles.item}>
                <div className={styles.row}>
                    <img src={usedIcon} alt=""/>
                    <span>Used Sub</span>
                </div>
                <p>{file83bLimit - remainingCount}</p>
            </div>
            <div className={styles.item}>
                <div className={styles.row}>
                    <img src={availableIcon} alt=""/>
                    <span>Available Sub</span>
                </div>
                <p>{remainingCount}</p>
            </div>
        </div>
    </div>
  );
};

export default Subscriptions;
