import React, {useState, FC, useMemo} from 'react';
import { useMutation } from '@tanstack/react-query';
import styles from './activitiesAndDocuments.module.scss';
import {classNames} from "primereact/utils";
import {TDocumentItem, TDocumentStatuses} from "../../../@types/global";
import documentIcon from '../../../assets/images/document-icon-purple.svg';
import {requests} from "../../../services/http-common";
import {useToast} from "../../../hooks/useToast";
import FilePreviewOverlay from "../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import sharedStyles from "../../../assets/sharedStyles.module.scss";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TDocumentsProps {
    data?: TDocumentItem[];
    showInitialCount?: number;
    companyId: number;
    status?: TDocumentStatuses;
    onContinueToFilling?: () => void;
}

const Documents: FC<TDocumentsProps> = ({
data = [],
showInitialCount = 3,
companyId,
status,
onContinueToFilling,
}) => {
    const [preview, setPreview] = useState<string>('');
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [previewLoading, setPreviewLoading] = useState<boolean>(false);
    const [isToggled, toggle] = useState(false);
    const { show } = useToast();


    const items = useMemo(
        () => {
            if (!data) return [];
            const firstThreeItems = data.slice(0, showInitialCount);
            return isToggled ? data : firstThreeItems;
        }, [data, isToggled]
    );
    const showMore = data.length > showInitialCount;

    const disableBodyScroll = () => {
        const bodyEl = document.getElementsByTagName('body')[0];
        bodyEl.setAttribute('class', 'hideBodyScroll');
    };

    const enableBodyScroll = () => {
        const bodyEl = document.getElementsByTagName('body')[0];
        bodyEl.removeAttribute('class');
    };

    const downloadGivenFile = async (downloadUrl: string, fileName: string) => {
        try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const getDownloadUrl = useMutation({
        mutationFn: async (documentId: number) => {
            return requests.get(
                `/api/companies/${companyId}/activity/file/info/${documentId}`,
            );
        },
        onSuccess: (response: Record<string, string>) => {
            const { url, fileName } = response;
            if (isPreview) {
                disableBodyScroll();
                setPreview(url);
            } else {
                downloadGivenFile(url, fileName).then(() => null);
            }
            setPreviewLoading(false);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            setPreviewLoading(false);
            const errorData = {
                error: error,
                variables: variables,
                retryFn: getDownloadUrl.mutate,
                show: show,
                customDefaultMessage: 'Something went wrong while downloading the template',
            };
            extractErrorMessage(errorData);
        },
    });

    const onDownload = (documentId: number) => {
        getDownloadUrl.mutate(documentId);
    };

    const onPreview = (documentId: number) => {
        setPreviewLoading(true);
        setIsPreview(true);
        getDownloadUrl.mutate(documentId);
    };

    const closePreview = () => {
        enableBodyScroll();
        setIsPreview(false);
        setPreview('');
    };

    return (
        <div className={styles.dataHolder}>
            <FilePreviewOverlay
                preview={preview}
                closePreview={closePreview}
                isLoading={previewLoading}
                hideDownloadButton
            />
            <div className={styles.header}>
                <span className={styles.label}>Documents</span>
                {
                    showMore && (
                        <button
                            onClick={() => toggle(!isToggled)}
                            className={classNames(sharedStyles.btnLink, sharedStyles.noDecor)}
                            type="button"
                        >
                            {isToggled ? 'View less' : 'View All'}
                        </button>
                    )
                }
            </div>
            <div className={styles.scrollWrapper}>
                {
                    items.length === 0 ? (
                        <div className={styles.noRecords}>No document records</div>
                    ) : (
                        <>
                            {
                                items.map((item) => {
                                    const { fileName, id } = item;
                                    return (
                                        <div className={styles.documentRow} key={id}>
                                            <div className={styles.info}>
                                                <img src={documentIcon} alt="" className={styles.docIcon}/>
                                                <p>{fileName}</p>
                                            </div>
                                            <div className={styles.actions}>
                                                <button type="button" onClick={() => onDownload(id)}>
                                                    <i className="bi bi-download"></i>
                                                </button>
                                                <button type="button" onClick={() => onPreview(id)}>
                                                    <i className="bi bi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            </div>
            {
                status === 'PENDING' && (
                    <ButtonPrimary
                        className={styles.continueBtn}
                        label="Continue"
                        styleType="secondaryOutline"
                        onClick={onContinueToFilling}
                    />
                )
            }
        </div>
    )
}

export default Documents;
