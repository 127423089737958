import BigNumber from "bignumber.js";
import moment from "moment";

const useDocumentGenerationDetails = () => {
    const formatNumberWithCommas = (number: number, isDecimal = false) => {
        const numberString = number.toString();
        const [integerPart, decimalPart] = numberString.split('.');
        const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
        );
        const formattedDecimalPart = isDecimal
            ? (decimalPart || '.00').padEnd(2, '0')
            : decimalPart
                ? `.${decimalPart}`
                : '';
        return `${formattedIntegerPart}${formattedDecimalPart}`;
    };
    const formatSSN = (numberString: string) => {
        let res = '';
        for (let i = 0; i < numberString.length; i++) {
            if (i === 3 || i === 5) {
                res += `-${numberString[i]}`;
            } else {
                res += `${numberString[i]}`;
            }
        }
        return res;
    };
    const calculateFormula = (
        formula: string,
        values: Record<string, string | number | Date | undefined>,
    ) => {
        const mathVal = '';
        const isPricePerShareNumber = typeof values.price_per_share === 'number';
        const isNumberOfSharesNumber = typeof values.number_of_shares === 'number';
        const isPricePaidNumber = typeof values.price_paid === 'number';
        if (
            formula === 'price_per_share*number_of_shares' &&
            isPricePerShareNumber &&
            isNumberOfSharesNumber
        ) {
            const bigPricePerShare = new BigNumber(values.price_per_share as number);
            const bigNumberOfShares = new BigNumber(values.number_of_shares as number);
            const res = bigPricePerShare.times(bigNumberOfShares);
            return res.toString();
        }
        if (
            formula === 'price_paid*number_of_shares' &&
            isPricePaidNumber &&
            isNumberOfSharesNumber
        ) {
            const bigPricePaid = new BigNumber(values.price_paid as number);
            const bigNumberOfShares = new BigNumber(values.number_of_shares as number);
            const res = bigPricePaid.times(bigNumberOfShares);
            return res.toString();
        }
        if (
            formula === '(price_per_share*number_of_shares) - (price_paid*number_of_shares)' &&
            isPricePerShareNumber &&
            isNumberOfSharesNumber &&
            isPricePaidNumber
        ) {
            const bigPricePerShare = new BigNumber(values.price_per_share as number);
            const bigNumberOfShares = new BigNumber(values.number_of_shares as number);
            const bigPricePaid = new BigNumber(values.price_paid as number);
            const production1 = bigPricePerShare.times(bigNumberOfShares);
            const production2 = bigPricePaid.times(bigNumberOfShares);
            const res = production1.minus(production2);
            return res.toString();
        }
        return mathVal;
    }
    const formatDate = (date: Date) => {
        return moment(date).format("MMMM DD, YYYY");
    };
    return {
        formatNumberWithCommas,
        formatSSN,
        calculateFormula,
        formatDate,
    }
}

export default useDocumentGenerationDetails;
