import { Checkbox } from 'primereact/checkbox';
import React, { FC } from 'react';

import styles from '../styles.module.scss';
import {stepsKeys, TStep} from '../types';
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";

const getTermsData = () => {
  return [
    '<span>The purpose of this service is to enable you to send your 83(b) election to the IRS without visiting the post office or private delivery service and to obtain the IRS date-stamped copy of your filing.  We are not acting as your agent in any capacity.  No part of this service, including any correspondence you receive from us in relation to this service, may be interpreted as legal or tax advice. Any correspondence you receive from us in relation to your submission is provided solely as a courtesy.</span>',
    'You are responsible for determining whether you need to make an 83(b) election.',
    'You are responsible for determining whether you should make an 83(b) election.',
    'You are responsible for the accuracy and contents of your 83(b) election.',
    `You authorize us to access and send your submission on your behalf, including through a third-party vendor at our sole discretion. The sender will be Corpora, Inc., c/o [your name] (or similar wording), listing our mailing address as the return address.`,
    'You authorize us to receive and access the IRS date-stamped copy of your filing, if received, at our mailing address on your behalf.',
    'You acknowledge and agree that you will use this service exclusively for the purpose for which it is provided, in good faith.',
    '<span>We reserve the right, in our sole and absolute discretion, to reject sending your submission.  We will inform you within seven business days in such case.  We will refund your payment, unless we determine, at our sole discretion, that you did not use the service in good faith, in which case your payment will not be refunded.</span>',
    'You acknowledge that once you submit your order, you cannot make any changes to your submission.',
    'We will not accept submissions later than 21 days after the date of transfer of your equity.',
    'You (and your spouse, if applicable) will have one day to sign the generated 83(b) election, after which the draft will be voided and you will have to start all over again.  Your payment will be refunded.',
    'This service is provided “as is” and “as available”.  We are not responsible for matters beyond our control, such as lost items.',
    'Our paid service exclusively consists of: (i) sending your signed cover letter, signed 83(b) election form, an additional copy of your cover letter or 83(b) election form, and a postage-paid return envelope to the IRS; (ii) providing you with a postmarked USPS Certified Mail Receipt (in case of USPS only), a tracking number, and proof of delivery (if available); and (iii) providing you with the IRS date-stamped copy of your filing (if received).',
    'We will email you the scanned copies of any physical document(s) as it/they become(s) available to us.  However, some document(s) may only be in digital form, in which case we’ll email you the digital document(s).  You are responsible for the safe-keeping of such scanned copies and digital documents.',
    '<span>Within 90 days of submitting your 83(b) election service order, you may request to have the original(s) of any scanned document(s) mailed to you, at an additional service charge.  If you do not make this request within 90 days of submitting your order, we will have no further obligation to retain any physical or digital documents or information related to your submission.</span>',
    'If you have been referred to this service or invited to use this service by your employer, lawyer, or vendor, then you authorize us to share information about your submissions, including any scanned documents or digital documents pertaining to your submissions, with that employer, lawyer, or vendor.',
    'If your use of this service is being paid for by your employer, then your employer may request us to return, delete, or destroy information or documents pertaining to your submission for confidentiality purposes.  In such cases, we will notify you of this request, and you will continue to be able to use, access, or download your information and documents, or request for physical documents to be mailed to you, for an additional 30 days of your employer’s request (or 90 days of submitting your order, whichever ends later).',
    'We will use the address you’ve included in your 83(b) election form to determine the IRS address where your submission should be sent.',
  ]
};

interface ITermsStepProps {
  setStep: React.Dispatch<React.SetStateAction<TStep>>;
  isTermsAccepted: boolean;
  setTermsState: (vl: boolean) => void;
  yourName?: string;
  signAndSubmit: () => void;
  isPublic?: boolean;
  paymentEnabled: boolean;
}

const TermsStep: FC<ITermsStepProps> = ({
setStep,
isTermsAccepted,
setTermsState,
signAndSubmit,
isPublic,
paymentEnabled,
}) => {
  const handleSubmit = () => {
    if (!isTermsAccepted) return null;
    if (isPublic) {
      setStep(stepsKeys.finish);
    } else {
      signAndSubmit();
      if (paymentEnabled) {
        setStep(stepsKeys.pay);
      }
    }
  }
  return (
      <div className={styles.termsHolder}>
        <div className={styles.termsWidgetWrapper}>
          <div className={styles.termsWidget}>
            <h3>Terms for 83(b) Election Service</h3>
            <div className={styles.termRows}>
              {getTermsData().map((item, index) => {
                return (
                    <div key={index} className={styles.term}>
                      <span>{`${index + 1}. `}</span>
                      {index === 7 || index === 0 || index === 14 ? (
                          <p dangerouslySetInnerHTML={{__html: item}}/>
                      ) : (
                          <p>{item}</p>
                      )}
                    </div>
                );
              })}
              <div key={getTermsData().length + 1} className={styles.term}>
                <span>{`${getTermsData().length + 1}. `}</span>
                {` `}
                <p>
                  Your use of this service remains subject to Corpora’s{' '}
                  <a
                      href="https://corpora.us/terms-of-service/"
                      target="_blank"
                      className={styles.lnk}
                      rel="noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                      href="https://corpora.us/privacy-policy/"
                      target="_blank"
                      className={styles.lnk}
                      rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className={styles.checkboxHolder}>
              <Checkbox
                  inputId="inputid"
                  name="terms"
                  onChange={() => setTermsState(!isTermsAccepted)}
                  checked={isTermsAccepted}
              />
              <label htmlFor="inputid">I have read and agree to these terms.</label>
            </div>
            <div className={styles.commonFooter}>
              <ButtonPrimary
                  label="Back to review"
                  styleType="secondaryOutline"
                  type="button"
                  onClick={() => setStep(stepsKeys.review)}
              />
              <ButtonPrimary
                  type="button"
                  onClick={handleSubmit}
                  label={isPublic ? "Next" : "Accept and Sign"}
                  disabled={!isTermsAccepted}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default TermsStep;
