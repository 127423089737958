import React, { useState, FC } from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {Navigate, Route, Routes} from "react-router-dom";
import AdminDashboard from "../../pages/AdminDashboard/AdminDashboard";
import ManageUser from "../../pages/ManageUser/ManageUser";
import Subscriptions from "../../pages/Subscriptions/Subscriptions";
import File83bDashboard from "../../pages/File83bDashboard/File83bDashboard";
import {DocumentsList} from "../../modules/Document/components/DocumentsList/DocumentsList";

import styles from "./layout.module.scss";
import {TUserCompanyConfigItem} from "../../@types/global";
import BulkUploadUsers from "../../pages/BulkUploadAndDraftUsers/BulkUploadUsers/BulkUploadUsers";
import {classNames} from "primereact/utils";
import Acknowledgements from "../../pages/Acknowledgement/Acknowledgements";

interface TLayout {
    defaultCompany: TUserCompanyConfigItem;
    companies: TUserCompanyConfigItem[];
    email: string;
    userLogOut: () => void;
    userName: string;
    userId: number;
    logOutLoading: boolean;
}

const Layout:FC<TLayout> = ({
    defaultCompany,
    companies,
    email,
    userLogOut,
    userName,
    userId,
    logOutLoading,
}) => {
    const [companyInfo, updateCompanyInfo] = useState(defaultCompany);
    const {
        companyId,
        companyName,
        file83bLimit,
        remainingCount,
        role,
        templateCode,
        templateSpouseCode,
        companyCode,
        filingCreationEnabled,
        paymentEnabled,
        subscriptionMenuVisible,
        skipGrantHeaderRecognizing,
    } = companyInfo;
    const isAdmin = role === "ADMIN";
    const hasSpouseFeature = Boolean(templateSpouseCode);
    return (
        <div className={classNames(styles.layout, {
            [styles.loading]: logOutLoading,
        })}>
            <Sidebar
                isAdmin={Boolean(isAdmin)}
                role={role}
                companyName={companyName}
                companies={companies}
                selectedCompanyId={companyId}
                updateCompanyInfo={updateCompanyInfo}
                companyCode={companyCode}
                subscriptionMenuVisible={subscriptionMenuVisible}
            />
            <Header userLogOut={userLogOut} fullName={userName} email={email}/>
            <main className={styles.content}>
                <Routes>
                    <Route
                        path="/filing/*"
                        element={
                            <File83bDashboard
                                companyId={companyId}
                                templateCode={templateCode}
                                templateSpouseCode={templateSpouseCode}
                                email={email}
                                fullName={userName}
                                userId={userId}
                                key={companyId}
                                paymentEnabled={paymentEnabled}
                                filingCreationEnabled={filingCreationEnabled}
                            />
                        }
                    />
                    <Route path="/documents/*" element={<DocumentsList />}/>
                    <Route path="/acknowledgements/*" element={<Acknowledgements />} />
                    {isAdmin ? (
                        <>
                            <Route
                                path="/admin-dashboard"
                                element={
                                    <AdminDashboard
                                        companyId={companyId}
                                        key={companyId}
                                        hasSpouseFeature={hasSpouseFeature}
                                        email={email}
                                    />
                                }
                            />
                            <Route
                                path="/manage-user"
                                element={<ManageUser companyId={companyId} key={companyId} />}
                            />
                            <Route
                                path="/grants"
                                element={<BulkUploadUsers companyId={companyId} key={companyId} skipGrantHeaderRecognizing={skipGrantHeaderRecognizing} />}
                            />
                            <Route
                                path="/subscriptions"
                                element={
                                    <Subscriptions
                                        file83bLimit={file83bLimit}
                                        remainingCount={remainingCount}
                                        key={companyId}
                                    />
                                }
                            />
                            <Route
                                path="/*"
                                element={<Navigate to="/admin-dashboard" replace/>}
                            />
                        </>
                    ) : (
                        <>
                            <Route path="/*" element={<Navigate to="/filing" replace/>}/>
                        </>
                    )}
                </Routes>
            </main>
        </div>
    )
}

export default Layout;
