import React, {FC, useRef, useEffect} from 'react';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import styles from "./textEditor.module.scss";
import draftUsersStyles from '../draftUsers.module.scss';

const getValidationSchema = (fieldName: string, isEmail: boolean) => {
    return Yup.object().shape({
        [fieldName]: isEmail
            ? Yup.string().email('Must be a valid email').required('Email is required')
            : Yup.string().required('This field is required'),
    });
};

interface TTextFieldEditorProps {
    fieldName: string;
    fieldValue: string;
    submitUpdate: (values: any) => void;
    updateEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    isEmail?: boolean;
    columnWidth: number;
}
const TextFieldEditor: FC<TTextFieldEditorProps> = ({
fieldName,
fieldValue,
submitUpdate,
isEmail = false,
updateEditMode,
columnWidth,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const validationSchema = getValidationSchema(fieldName, isEmail);
    const form = useForm({
        defaultValues: {
            [fieldName]: fieldValue
        },
        resolver: yupResolver(validationSchema),
    });
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = form;
    const fieldError = errors[fieldName]?.message;
    const formValues = watch();
    const currentFieldValue = watch(fieldName);
    const trimAndReduceMultipleSpaces = (str: string) => {
        return str.trim().replace(/\s+/g, ' ');
    }
    const onBlurFields = () => {
        const formattedFormValue = trimAndReduceMultipleSpaces(formValues[fieldName] || '');
        const isChanged = fieldValue !== trimAndReduceMultipleSpaces(currentFieldValue || '');
        if (isChanged) {
            submitUpdate({ [fieldName]: formattedFormValue });
        } else {
            updateEditMode(false);
        }
    }
    const leftRightGap = 3;
    const inputElStyles = {
        width: `${columnWidth - (leftRightGap*2)}px`,
    }
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <td className={draftUsersStyles.tableData} style={{ width: `${columnWidth}px` }}>
            <form className={styles.textEditor} onSubmit={handleSubmit(submitUpdate)}>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({field}) => {
                        return (
                            <>
                                <InputText
                                    ref={inputRef}
                                    style={inputElStyles}
                                    autoComplete="off"
                                    value={field.value as string}
                                    onBlur={onBlurFields}
                                    name={fieldName}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                    }}
                                />
                                {fieldError && <p className={styles.fieldError}>{fieldError}</p>}
                            </>
                        );
                    }}
                />
            </form>
        </td>
    )
}

export default TextFieldEditor;